<template>
    <div>
        <card-header title="Add-On" icon="fa-layer-plus"/>
        <card-toolbar>
            <button @click="$openCard('edit-trip-addon', {addonId: addon.id}, card)"><i class="fas fa-pencil mr-2"/>Edit Add-On</button>
        </card-toolbar>

        <card-body>
            <card-list>
                <trip-list-item :trip="trip" :card="card"/>
                <subheader-list-item title="Add-On Details" icon="fa-list"/>
                <data-row title="Add-On">{{ addon.title }}</data-row>
                <data-row title="Summary">{{ addon.summary }}</data-row>
                <data-row title="Status">{{ addon.status | capitalize }}</data-row>
                <data-row title="Single Cost">{{ addon.single_cost | currency }}</data-row>
                <data-row title="Double Cost">{{ addon.double_cost | currency }}</data-row>
                <data-row title="Type">{{ addon.type | capitalize }}</data-row>

                <separator-list-item/>

                <navigation-list-item
                    icon="fa-street-view"
                    title="View Participants"
                    :badge="addon.participantCount"
                    :active="(child && child.definition.component === 'addon-participants')"
                    @click="$openCard('addon-participants', {addonId: addon.id}, card)"
                />

            </card-list>
        </card-body>
    </div>
</template>

<script>
    import CardHeader from "@/TIER/components/CardHeader";
    import CardList from "@/TIER/components/CardList";
    import CardToolbar from "@/TIER/components/CardToolbar";
    import SearchListItem from "@/TIER/components/SearchListItem";
    import SubheaderListItem from "@/TIER/components/SubheaderListItem";
    import SeparatorListItem from "@/TIER/components/SeparatorListItem";
    import NavigationListItem from "@/TIER/components/NavigationListItem";

    import {client as http} from '@/http_client';
    import CardBody from "@/TIER/components/CardBody";
    import TripAddOnListItem from "@/components/TripAddOnListItem";
    import DataRow from "@/TIER/components/DataRow";
    import async from "async";
    import TripListItem from "../../../components/TripListItem.vue";

    export default {
        props: ['card', 'child', 'props'],
        components: {TripListItem, DataRow, TripAddOnListItem, CardBody, NavigationListItem, SeparatorListItem, SubheaderListItem, SearchListItem, CardToolbar, CardList, CardHeader},
        data: function() {
            return {
                trip: {},
                addon: {
                    participants: []
                }
            };
        },
        methods: {
            loadAddon: function(force) {
                this.$emit('loading', true);

                async.series([
                    (cb) => {
                        http.get('/api/addons/' + this.props.addonId, {force: true}).then(response => {
                            this.addon = response.data;
                            cb();
                        });
                    },
                    (cb) => {
                        http.get('/api/trips/' + this.addon.trip_id, {force: true}).then(response => {
                            this.trip = response.data;
                            cb();
                        });
                    }
                ], err => {
                    this.$emit('loading', false);
                });
            },
            reload: function() {
                this.loadAddon(true);
            }
        },
        watch: {
            'props.addonId': function() {
                this.loadAddon(true);
            }
        },
        mounted() {
            this.loadAddon(true);
        }
    };
</script>

<style lang="scss" scoped>

</style>
